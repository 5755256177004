.tooltip {
  width: 280px;
  color: @color-white;
  &__title {
    margin: 8px 0 8px;
    font-size: 18px;
    font-weight: 600;
  }
  &__list {
    margin: 0 0 8px;
    padding: 0;
    font-size: 14px;
    list-style: none;
  }
  &__element {
    &:before {
      content: "―";
      margin-right: 4px;
    }
  }
}
