.badge {
  display: inline-block;
  vertical-align: middle;
  min-width: 58px;
  margin-left: 4px;
  height: 26px;
  background-color: @color-super-light-gray;
  border-radius: @border-radius-default;
  padding: 0 8px;
  line-height: 26px;
  position: relative;
  font-size: 12px;
  color: @color-dark-gray;
  border: @none;
  .transition-bg-color();
  &:hover {
    background-color: @color-light-gray;
  }
}
