@import "~less/variables.less";
@import "../../node_modules/@ckeditor/ckeditor5-build-balloon-block/src/plugins/link/theme.css";
@import "../../node_modules/@ckeditor/ckeditor5-build-balloon-block/theme/theme.css";

.content-text {
  word-wrap: break-word;
  position: relative;

  &__title {
    padding: 0;
    border: none;
    font-weight: bold;
    font-size: 26px;
    letter-spacing: -0.5px;
    color: @color-black;
    resize: none;
    overflow: hidden;
    margin-bottom: 6px;
    height: 34px;
    width: 100%;

    &::-webkit-input-placeholder {
      color: @color-default-gray;
    }

    &::-moz-placeholder {
      color: @color-default-gray;
    }
  }

  .fr-video {
    display: block;
    text-align: center;

    iframe {
      max-width: 100%;
    }
  }

  h1, h2, h3, h4 {
    font-weight: bold;
    margin: 0 0 16px;
  }

  h1, h2, h3, h4, p, ul li, ol li, a {
    color: @color-black;
  }

  h1, h2, h3 {
    letter-spacing: -0.5px;
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
  }

  h4 {
    font-size: 18px;

  }

  h4, p, ul li, ol li, a, em, quote, code, blockquote {
    line-height: 27px;
  }

  p, ul li, ol li, a {
  }

  label, p, ul li, ol li, a, em, quote, code, table, blockquote {
    font-size: 18px;
  }

  figure {
    margin: 0;
  }

  mark {
    border-radius: 4px;
  }

  mark[class*='marker'] {
    padding: 0px 11px;
    border-radius: 0;
  }

  img {
    border-radius: @border-radius-default;
    max-width: 100%;
  }

  u, a {
    &:not(.re-button) {
      text-decoration: none;
      position: relative;
      z-index: 1;
      border-bottom: 1px solid @color-black;
    }
  }

  ul, ol {
    margin: 0;
    padding: 0;
  }

  ul li, ol li {
    display: block;
    padding-bottom: 16px;
    margin: 0 0 0 32px;

    &:before {
      content: "1.";
      counter-increment: item;
      position: absolute;
      display: inline-block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 78px;
      margin-left: -78px;
      padding-right: 12px;
      text-align: right;
    }

    &:last-child {
      padding-bottom: 24px;
    }
  }

  ul {
    li:before {
      content: "\2022";
      font-weight: 700;
    }
  }

  ol {
    counter-reset: item;

    li:before {
      content: counter(item) ". ";
      counter-increment: item;
    }
  }

  p, quote, code, em, code, table {
    padding: 0 0 24px;
    margin: 0;
  }

  blockquote {
    margin: 0 0 24px;
    line-height: 1.5;
    font-style: italic;
  }

  blockquote > *:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  strong {
    font-weight: bold;
  }

  em, quote {
    font-style: italic;
  }

  quote {
    padding-left: 16px;
    border-left: 1px solid @color-dark-gray;
  }

  hr {
    width: 100%;
    height: 2px;
    background-color: @color-light-gray;
    border: none;
  }

  code {
    font-size: 12px;
    line-height: 11px;
    display: block;
    font-family: monospace;
    color: @color-black;
    padding: 16px 20px;
    background: rgba(0, 0, 0, .03);
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: @border-radius-default;
    margin: 0;
  }

  p {
    &.content-text__caption {
      text-align: center;
      color: #9aa5ab;
      font-size: 14px;
    }
  }

  pre {
    white-space: pre-line;
  }

  &_readonly {
    .table td {
      border: 1px double;
    }

    .ck-delimiter,
    .ck-delimiter::before {
      display: none !important;
    }
  }

  .ck-content {
    &__image {
      clear: both;
      text-align: center;
      margin: 1em 0;
      position: relative;
      overflow: hidden;

      &_side,
      &_align-left,
      &_align-center,
      &_align-right {
        max-width: 50%;
      }

      &_side {
        float: right;
        margin-left: var(--ck-image-style-spacing);
      }

      &_align-left {
        float: left;
        margin-right: var(--ck-image-style-spacing);
      }

      &_align-center {
        margin-left: auto;
        margin-right: auto;
      }

      &_align-right {
        float: right;
        margin-left: var(--ck-image-style-spacing);
      }

      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }

      figcaption {
        background-color: transparent !important;
        color: #9aa5ab;
        font-size: 14px;
        padding: 16px;
        display: block !important;
        text-align: center;
      }
    }
  }
}
