.button {
  cursor: pointer;
  color: @color-white;
  display: inline-block;
  font-size: 16px;
  border: 1px solid;
  border-radius: @border-radius-form;
  box-sizing: @border-box;
  font-weight: 600;
  line-height: 20px;
  padding: 9px 20px;
  margin: 0;
  white-space: nowrap;
  -webkit-appearance: @none;
  text-decoration: @none;
  &::-moz-focus-inner {
    border: 0;
  }
  &:disabled {
    cursor: default;
  }

  &_normal {
    font-weight: normal;
  }

  &_mobile {
    @media (max-width: 550px) {
      width: 100%;
      margin: 0!important;
    }
  }

  &_mobile_780 {
    @media @Media-max-780 {
      width: 100%;
      margin: 0!important;
      text-align: center;
    }
  }

  &_mb_17 {
    @media (max-width: 550px) {
      margin-bottom: 17px!important;
    }
  }

  &_mt_16 {
    @media (max-width: 550px) {
      margin-top: 16px!important;
    }
  }

  &_mobile-700 {
    @media (max-width: 700px) {
      width: 100%;
      margin: 0!important;

      &:first-child {
        margin-bottom: 16px!important;
        margin-top: 32px!important;
      }
    }
  }

  &__add {
    font-size: 14px;
    color: @color-blue;
  }

  &_not-button {
    padding: 0;
    border: none;
    color: inherit;
    background: transparent;
  }

  &_onboarding {
    position: relative;
    width: 56px;
    height: 56px;
    background-color: @blue-primary;
    color: @white;
    border-radius: 100%;
    border: none;
    box-shadow: @big-shadow;
    transition: color .1s, background-color .1s;
    ui-icon {
      position: absolute;
      top: 12px;
      left: 12px;
      transition: opacity .1s, transform .1s;
    }
    ui-icon[name="plus"] {
      top: 16px;
      left: 16px;
      opacity: 0;
      transform: scale(.1);
    }
    &.tooltip-shown {
      background-color: @white;
      color: @blue-primary;
      ui-icon[name="plus"] {
        opacity: 1;
        transform: scale(1);
      }
      ui-icon[name="onboarding-hat"] {
        opacity: 0;
        transform: scale(.1);
      }
    }
  }

  &_theme-1 {
    border-color: @color-blue;
    background-color: @color-blue;
    .transition-bg-color();

    &:hover {
      background-color: @color-dark-blue;
      border-color: @color-dark-blue;
    }

    &:active {
      background-color: @color-super-light-blue;
      border-color: @color-super-light-blue;
    }

    &:disabled {
      background-color: @color-default-gray;
      border-color: @color-default-gray;
    }
  }

  &_theme-2 {
    border-color: @color-light-dark-gray;
    color: @color-light-black;
    background-color: @color-transparent;
    .transition-all();

    &:hover {
      border-color: @color-red;
      color: @color-red;
    }

    &:disabled {
      .disabled();
      border-color: @color-light-dark-gray;
      color: @color-gray;
    }
  }

  &_theme-3 {
    color: @color-light-black;
    background-color: @color-transparent;
    border-color: @color-light-dark-gray;
    .transition-all();

    &:hover {
      border-color: @color-default-gray;
    }

    &:active {
      background-color: @color-super-light-blue;
      border-color: @color-super-light-blue;
      color: @color-white;
    }

    &:disabled {
      .disabled();
      background-color: @color-default-gray;
      border-color: @color-default-gray;
      color: @color-white;
    }
  }

  &_theme-4 {
    background-color: @color-green;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    color: @color-white;
    justify-content: @center;
    text-decoration: @none;
    position: relative;
    display: flex;
    align-items: @center;
    transition: .3s;
    box-shadow: 0 0 30px rgba(158, 157, 163, .5);
    margin: 0 auto;
    cursor: pointer;
    border: @none;

    &:hover {
      background-color: @color-dark-light-green;
    }

    .icon-plus-add {
      color: @color-white;
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -12px 0 0 -12px;
      transition: .3s;
    }
  }

  &_theme-5 {
    border-color: @color-light-dark-gray;
    background-color: @color-white;
    color: @color-dark-gray;
    .transition-bg-color();

    &:hover {
      background-color: @color-light-dark-gray;
    }

    &:disabled {
      color: @color-light-dark-gray;
      border-color: @color-light-dark-gray;
      background-color: @color-light-dark-gray;
    }

    &_icon {
      padding-left: 40px;
      position: relative;

      i {
        position: absolute;
        left: 14px;
        top: 7px;
      }
    }
  }

  &_theme-6 {
    border-color: @color-red;
    background-color: @color-white;
    color: @color-red;
    .transition-bg-color();

    &:hover {
      background-color: @color-red;
      color: @color-white;
    }

    &:disabled {
      color: @color-light-dark-gray;
      border-color: @color-light-dark-gray;
      background-color: @color-light-dark-gray;
    }
  }

  &_theme-7 {
    border-color: @color-light-dark-gray;
    color: @color-dark-gray;
    .transition-all();

    &:hover {
      background-color: @color-blue;
      border-color: @color-blue;
      color: @color-white;
    }
  }

  // синий текст на прозрачном фоне
  &_theme-8 {
    padding: 0;
    border: none;
    background: transparent;
    color: @color-blue;
  }

  // зеленая заливка и белый текст
  &_theme-10 {
    padding: 12px 40px;
    border-radius: 100px;
    line-height: 17px;
    font-size: 14px;
    background-color: @color-green;
    color: @color-white;
  }

  &_mr-16 {
    margin-right: 16px;
  }

  // синяя круглая кнопка с иконкой
  &_theme-11 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: none;
    background-color: @color-blue;
    transition: background-color .1s;
    &:disabled {
      background-color: @color-default-gray;
      color: @color-white;
    }
    &:hover{
      color: @color-white;
    }
  }
}
