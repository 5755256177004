.textarea {
  .form-prop();
  padding: 12px 16px;

  &_share-text {
    padding: 16px;
    font-size: 18px;
    line-height: 1.5;
    color: @color-black;
  }
}
