:root {
  --ck-z-default: 100 !important;
  --ck-z-modal: calc(var(--ck-z-default) + 999) !important;
  --ck-input-border: @border-default !important;
  --ck-color-focus-border: @color-light-gray !important;
  --ck-color-input-border: @color-light-gray !important;
  --ck-spacing-large: 10.5px !important;
}

mark {
  background-color: transparent;
}

.ck {
  .ck-toolbar {
    max-width: 800px;
    margin: 0 auto;
    border-color: transparent !important;
    border-radius: 8px !important;
  }

  .ck-button.ck-on[class*='pen-']:before,
  .ck-button.ck-on[class*='marker-']:before,
  .ck-heading-dropdown .ck-dropdown__panel .ck-button.ck-on:after {
    width: 24px;
    height: 24px;
    display: inline-block;
    content: ' ' !important;
    mask: url('../assets/icons/done.svg') no-repeat 50% 50%;
    mask-size: cover;
  }
  .ck-button.ck-on[class*='pen-']:before {
    background-color: @color-white !important;
  }

  .ck-button.ck-on[class*='marker-']:before {
    background-color: @color-black !important;
  }

  .ck-heading-dropdown .ck-dropdown__panel .ck-button.ck-on:after {
    background-color: @color-green !important;
  }

  .ck-link .ck-link__url {
    border: none;
  }

  .ck.ck-button:not(.ck-button_with-text) {
    z-index: 9999999999;
  }
}
