.content-item {
  &__comments {
    margin-right: 16px;
  }
  &__cover {
    background-size: cover;
    background-position: @center @center;
    width: 100%;
    height: 184px;
    display: flex;
    align-items: @center;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: @border-radius-default;
    border-top-right-radius: @border-radius-default;
  }

  &__inner {
    padding: 24px;
    display: inline-block;
    box-sizing: @border-box;
    font-size: 14px;
    flex: 1 1 500px;
    width: 100%;
    @media @Media-max-780 {
      padding: 24px 16px;
    }
    //overflow: hidden; // todo ? bug in actions for post
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;
    position: relative;

    &-inner {
      box-sizing: border-box;
      width: 100%;
    }
  }

  &__link {
    cursor: pointer;
    font-size: 26px;
    font-weight: bold;
    color: @color-black;
    //white-space: nowrap;
    //max-width: ~'calc(100% - 55px)';
    //overflow: hidden;
    //text-overflow: ellipsis;
    display: inline-block;
    box-sizing: border-box;
    padding-top: 4px;
    position: relative;
    word-wrap: break-word;
    max-width: 90%;
    @media @Media-max-550 {
      user-select: none;
    }

    &_no-pointer {
      cursor: auto;
    }
  }

  &__collapser {
    margin-bottom: -12px;
  }

  &__link-preview {
    &_margin {
      margin: 16px 16px 16px 0;
    }

    & + .content-item__header-inner {
      width: ~'calc(100% - 70px)';
      padding-left: 16px;
    }
  }

  &__source {
    margin: 8px 0 16px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-link, &-name, &-data, &-value, &-read {
      font-size: 14px;
      color: @color-gray;
      text-decoration: none;
      position: relative;
      z-index: 1;
      padding-right: 20px;
      line-height: 1;
    }

    &-data {
      min-width: fit-content;
    }

    &-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      name-width: 100%;
    }

    &-data, &-link, &-value, &-read {
      &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        top: 50%;
        margin-top: -2px;
        right: 8px;
        z-index: 2;
        background-color: @color-gray;
      }

      &_no-dot {
        &:before {
          display: none;
        }
      }

      &_green {
        color: #31c8ab;
        padding-right: 0!important;
      }

      &_right {
        &:before {
          left: -10px;
        }
      }
    }
  }

  &__menu {
    position: absolute;
    // z-index: 2;
    top: -5px;
    right: -13px;
    .menu-dots();

    &_top_0 {
      top: 0;
    }
  }

  &__popup {
    display: none;
    right: 12px;
    top: 100%;
    margin-top: -5px;

    @media (max-width: 550px) {
      position: fixed!important;
      min-width: 100%!important;
      left: 0!important;
      bottom: 0!important;
      top: initial!important;
      // z-index: 5!important;
    }
  }

  &__icon {
    // position: absolute;
    top: 4px;
    z-index: 1;

    &:last-child {
      right: 0;
    }

    &:first-child {
      right: 30px;
    }

    &-unlink, &-document {
      margin-right: 8px;
    }

    &-unlink, &-document {
      position: absolute;
      left: 0;
      top: -5px;
    }

    &-unlink {
      color: @color-green;
    }

    &-document {
      color: @color-blue;
    }
  }

  &__icon-link {
    margin-right: 7px;
    @media @Media-max-550 {
      margin-right: 4px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: 24px;

    &-left {
      // width: calc(100% - 95px);
    }

    &-right {
      display: flex;
      margin-left: auto;
    }
  }
}
