.label {
  font-size: 12px;
  //font-family: @gotha-reg;
  color: @color-gray;
  margin-top: 24px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  margin-bottom: 8px;
  .user-select ();
  &_without {
    &-mb {
      margin-bottom: 0;
    }
  }
  &_relative {
    position: relative;
    z-index: 1;
  }
  &__info {
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-left: 5px;
    margin-top: -13px;
    vertical-align: middle;
  }
}
