.link {
  font-size: 14px;
  color: @color-blue;
  &_not-link {
    color: inherit;
    text-decoration: none;
    &:hover, &:active, &:focus, &:visited {
      text-decoration: none;
      color: inherit;
    }
  }
  &_without-decoration {
    cursor: pointer;
    text-decoration: @none;
  }
  &-gray {
    color: @color-gray;
  }
}
