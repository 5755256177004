@import "button";
@import "check-box";
@import "input";
@import "label";
@import "radio";
@import "textarea";
@import "select";

.form {
  &_relative {
    position: relative;
    z-index: 1;
  }

  &__button_mobile {
    @media (max-width: 550px) {
      width: 100%;
    }
  }

  &__description {
    font-size: 14px;
    color: @color-gray;
    line-height: 17px;

    @media (max-width: 550px) {
      font-weight: normal;
      margin-bottom: 32px;
    }
  }
  &__input_padding {
    padding: 0 40px 0 40px;
  }

  &__enter, &__reset {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    cursor: pointer;
  }

  &__reset {
    font-size: 14px;
    color: @color-red;
    line-height: 40px;
    padding: 0 16px;
  }

  &__enter {
    border: 0;
    width: 42px;
    background-color: @color-orange;
    border-top-right-radius: @border-radius-form;
    border-bottom-right-radius: @border-radius-form;

    .icon-arrow {
      left: 0;
      color: @color-white;
    }
  }

  &__row {
    text-align: left;
    position: relative;

    &_fast-search {
      width: ~'calc(100% - 292px)';

      @media (max-width: 1100px) {
        width: 100%;
      }
    }

    &_success {
      .input, .textarea {
        border-color: @color-green !important;
      }
    }

    &_error {
      .input, .textarea {
        border-color: @color-red !important;
        background-color: @color-light-red !important;
      }

      .icon(info);

      &:before {
        position: absolute;
        z-index: 2;
        right: 10px;
        bottom: 7px;
        font-size: 24px;
        color: @color-red;
        .icon-prop();
      }

      .form {
        &__validation {
          display: block;
        }
      }
    }

    &_error-no-icon {
      .input, .textarea {
        padding-right: 175px;
        border-color: @color-red !important;
        background-color: @color-light-red !important;
      }

      .form {
        &__validation {
          display: block;
        }

        &__input-url-error {
          color: @color-red;
        }
      }
    }

    &_active {
      .input, .textarea {
        border-color: @color-light-blue !important;
      }
    }

    &_end {
      text-align: right;
    }

    &_start {
      text-align: left;
    }

    &_full-width {
      width: 100%;
      flex: 1 0 100% !important;
      margin-top: 24px;
      &_no-extra-margin {
        margin-top: 0;
      }
    }

    &_space-between {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &_no-wrap {
      flex-wrap: nowrap;
    }

    &_align-center {
      align-items: center;
    }

    &_center {
      text-align: center;
    }

    &_mt-24 {
      margin-top: 24px;
    }

    &_mb-22 {
      margin-bottom: 22px;
    }

    &_mb-36 {
      margin-bottom: 36px;
    }

    &_inline {
      display: inline-block;
      margin: 0 auto;
    }
  }

  &__validation {
    display: none;
    background-color: @color-red;
    border-radius: @border-radius-5;
    color: @color-white;
    font-size: 14px;
    padding: 8px 16px;
    bottom: 55px;
    right: -1px;
    z-index: 3;
    position: absolute;
    z-index: 3;

    &:before {
      content: "";
      width: 100%;
      background-color: @color-red;
      border-radius: @border-radius-5;
      height: 10px;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 1;
    }

    &:after {
      content: "";
      background-color: @color-red;
      border: 1px solid rgba(0, 0, 0, 0.06);
      height: 12px;
      width: 12px;
      position: absolute;
      margin-left: 4px;
      bottom: -6px;
      right: 16px;
      transform: rotate(45deg);
    }
  }

  &__link {
    position: absolute;
    right: 0;
    top: -4px;
    z-index: 1;

    &_reminder {
      font-size: 12px;
      color: @color-blue;
      top: 24px;
      text-decoration: none;
    }

    &_type-desktop {
      @media (max-width: 550px) {
        display: none;
      }
    }

    &_type-mobile {
      position: relative;
      display: none;
      width: 100%;
      text-align: center;
      margin-top: 68px;
      margin-bottom: 15px;
      font-size: 16px;

      @media (max-width: 550px) {
        display: block;
      }
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: @color-dark-gray;
    line-height: 1;
    padding-top: 24px;
    display: block;
    width: 100%;
    margin: 0 0 10px 0;

    &_margin-top {
      margin-top: 16px;
    }

    &_without-padding {
      padding: 0;
    }

  }

  &__title-big {
    font-size: 20px;
    font-weight: 600;
    color: @color-black;
    letter-spacing: -0.5px;
    line-height: 24px;
    padding-top: 32px;
    display: block;
    width: 100%;
    margin: 0 0 10px 0;

    &:first-child {
      padding-top: 14px;
    }

    &_padding-top-24 {
      padding-top: 24px;
      margin-bottom: 8px;
    }

    &_without-padding-top {
      padding-top: 0;
      margin-bottom: 2px;
    }
  }

  &_colls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form__row {
      flex: 0 0 47.5%;
    }

    @media (max-width: 550px) {
      display: block;
    }
  }

  &_coll {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form__row {
      flex: 0 0 100%;

      &_mb-24 {
        margin-bottom: 24px;
      }
    }
  }

  &__icon, &__btn-search {
    position: absolute;
    z-index: 2;
    color: @color-gray;
    bottom: 0;
    text-align: @center;
    padding: 8px 9px;

    &_left {
      left: 0;
      padding-left: 16px;
    }

    &_right {
      right: 0;
      padding-right: 16px;
    }

    &-plus-add {
      vertical-align: middle;
      color: @color-blue;
      margin: -4px 4px 0 0;
    }
  }

  &__icon-close {
    position: absolute;
    z-index: 2;
    top: 25px;
    right: 32px;
  }

  &__sides {
    display: flex;
    justify-content: space-between;
    @media (max-width: 700px) {
      display: block;
    }
  }

  &__side {
    width: 49.5%;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    @media (max-width: 700px) {
      display: block;
      width: 100%;
    }

    &:first-child {
      padding-right: 12px;
      @media (max-width: 700px) {
        padding-right: 0;
      }
    }

    &:last-child {
      padding-left: 12px;
      @media (max-width: 700px) {
        padding-left: 0;
      }
    }
  }

  &__button-add {
    font-size: 14px;
    color: @color-blue;
    cursor: pointer;
  }

  &__footer-inner {
    border-top: 1px solid @color-light-gray;
    padding: 16px 0;
    width: 750px;
    margin: 26px auto;
  }


  &__input-url {
    max-width: 800px;
    margin: 0 auto 16px;
    width: 90%;

    .input {
      padding-left: 45px;
      padding-right: 150px;
    }
  }

  &__input-url-icon {
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 24px;
    margin-top: -12px;
    left: 16px;
    right: inherit;
  }

  &__input-error, &__input-button {
    position: absolute;
    top: 50%;
    right: 16px;
    font-size: 14px;
    line-height: 1.4rem;
    margin-top: -0.7rem;
    user-select: none;
  }

  &__input-button-icon {
    margin-top: -2px;
  }

  &__input-button {
    color: @color-gray;
    cursor: pointer;
  }
}
