.main {
  display: flex;
  box-sizing: @border-box;
  padding: 48px 80px;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  &__sidebar {
    flex: 1 0 264px;
    width: 264px;
    margin: 0 27px 0 0;
    max-width: 264px;
  }
  &__sidebar + &__content {
    width: calc(100% - 291px);
  }
  &__content {
    width: 100%;
    flex: 1 0 300px;
  }
}