.cdk-overlay-pane {
  padding: 0;
  max-width: none !important;
}

.cdk-overlay-container {
  z-index: 4 !important;
}

.cdk-overlay-pane__container-overflow_visible {
  .mat-dialog-container {
    overflow: visible;
  }
}

.mat-dialog-container {
  border-radius: 0 !important;
  padding: 0 !important;
  background-color: transparent;
}

.cdk-global-scrollblock {
  overflow-y: initial !important;
}

.mat-dialog-content {
  @media(max-width: 390px) {
    width: 100%;
  }
  &_any-height {
    max-height: unset !important;
  }
  &_default-border-radius {
    border-radius: @border-radius-default;
  }
  &_full-height {
    min-height: 100% !important;
  }
  &_no-margin {
    margin: 0 !important;
  }
  &_no-padding {
    padding: 0 !important;
  }
  &_relative {
    position: relative;
  }
  &_vertical-scroll {
    overflow: initial !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}
