@import "./variables";

.h1-text() {
  margin: 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
}

.h2-text() {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.005em;
}

.h3-text() {
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.rt-h1 {
  .h1-text();
}

.rt-h2 {
  .h2-text();
}

.rt-h3 {
  .h3-text();
}

// .text() {
//   font-size: 18px;
//   line-height: 26px;
//   color: @black;
//   font-weight: normal;
// }

/* --- */
// .text {
//   .text();
// }

/* --- */
.body-1-text() {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.body-2-text() {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}

.body-3-text() {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}

.rt-body, .rt-body-1 {
  .body-1-text();
}

.rt-body-2 {
  .body-2-text();
}

.rt-body-3 {
  .body-3-text();
}

// .error() {
//   font-size: 12px;
//   line-height: 16px;
//   color: @red;
//   font-weight: normal;
// }

.caption-text() {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.rt-caption {
  .caption-text();
}

@color-selectors: {
  black: @black;
  blue: @blue;
  blue-primary: @blue-primary;
  green: @green;
  grey-1: @grey-1;
  grey-3: @grey-3;
  grey-4: @grey-4;
  white: @white;
  yellow: @yellow;
};

each(@color-selectors, {
  .rt-color-@{key} {
    color: @value;
  }
});
