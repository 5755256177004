.user-status {
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  padding-left: 15px;
  &_master {
    color: #1ebfe9;
  }
  &_ex {
    color: #e86d31;
  }
  &_prof {
    color: #e9a93f;
  }
  &:before {
    content: '\2022';
    font-size: 18px;
    position: absolute;
    left: 4px;
    height: 4px;
    top: -6px;
    color: inherit;
  }
  &_no-dot {
    &:before {
      display: none;
    }
  }
}
