ul.jqtree-tree {
  list-style: none outside;
  margin: 0;
  padding: 17px 0 10px;

  ul.jqtree_common {
    list-style: none outside;
    margin-left: 10px;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    display: block;
  }

  span.jqtree-border {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: @color-gray-blue;
      border-radius: @border-radius-default;
    }
  }

  li.jqtree-closed > ul.jqtree_common {
    display: none;
  }

  li.jqtree_common {
    clear: both;
    list-style-type: none;
  }

  .jqtree__edit {
    display: none;
    background-image: url("../interface/edit-grey.svg");
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    position: absolute;
    z-index: 3;
    right: 8px;
    top: 6px;
    background-color: @color-gray-blue;

    &:hover {
      background-image: url("../interface/edit-dark.svg");
    }
  }

  .jqtree-toggler {
    text-decoration: none;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 3;
    left: 2px;
    top: 9px;

    &:before {
      content: "";
      border: 4px solid transparent;
      border-top: 4px solid @color-default-gray;;
    }

    &.jqtree-closed {
      top: 7px;
      left: 4px;

      &:before {
        transform: rotate(-90deg);
      }
    }
  }

  .jqtree-element {
    cursor: pointer;
    position: relative;

    &:hover, &_active {
      background-color: @color-gray-blue;
      border-radius: @border-radius-default;

      .jqtree__edit {
        display: inline-block;
      }
    }
  }

  .jqtree__count {
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 9px;
    color: @color-blue;
  }

  .jqtree-title {
    display: block;
    color: @color-dark-gray;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 100;
    padding: 8px 40px 8px 22px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    z-index: 2;

    @media (max-width: 549px) {
      padding-left: 8px;
    }

    &.jqtree-title-folder {
      margin-left: 0;
    }

    &:first-letter {
      text-transform: uppercase;
    }
  }

  li.jqtree-ghost {
    position: relative;
    z-index: 10;
    margin-right: 10px;

    span {
      display: block;
    }

    span.jqtree-line {
      background-color: @color-light-blue;
      height: 2px;
      padding: 0;
      position: absolute;
      top: -1px;
      width: 100%;
    }

    &.jqtree-inside {
      margin-left: 48px;
    }
  }

  li.jqtree-selected {
    & > .jqtree-element {
      .jqtree__count {
        display: none;
      }

      .jqtree-title {
        color: @color-blue;
      }

      &:after {
        content: "";
        background-color: @color-blue;
        border-radius: @border-radius-full;
        display: block;
        position: absolute;
        z-index: 2;
        top: 50%;
        right: 15px;
        margin-top: -4px;
        margin-left: -12px;
        width: 7px;
        height: 7px;
      }
    }
  }

  .jqtree-moving {
    cursor: move;

    & > .jqtree-element {
      height: 36px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 1px dashed @color-light-blue;
        border-radius: @border-radius-default;
      }
    }

  }

}

ul.jqtree-tree.jqtree-rtl {
  direction: rtl;

  ul.jqtree_common {
    margin-left: 0;
    margin-right: 8px;
  }

  .jqtree-title {
    margin-left: 0;

    &.jqtree-title-folder {
      margin-right: 0;
    }
  }

  li.jqtree-ghost {
    margin-right: 0;
    margin-left: 10px;

    span.jqtree-line {
      right: 2px;
    }

    &.jqtree-inside {
      margin-left: 0;
      margin-right: 48px;
    }
  }

  span.jqtree-border {
    right: -2px;
  }
}

span.jqtree-dragging {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 100;
  font-style: italic;
  color: @color-dark-gray;
  background-color: @color-gray-blue;
  opacity: 0.8;
  cursor: move;
  padding: 8px 22px;
  z-index: 5;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid @color-light-blue;
    border-radius: @border-radius-default;
  }
}
