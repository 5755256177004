.radio {
  .radio-check-box-prop ();
  width: 100%;
  line-height: 36px;
  &_inline {
    display: inline-block;
    width: auto;
  }
  &__side {
    display: inline-block;
    width: 50%;
  }
  &__input {
    &:checked + .radio__indicator:before {
      content: '';
      background-color: @color-blue;
    }
    &:disabled {
      & + .radio__indicator {
        &:before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: @border-radius-full;
          background-color: @color-gray;
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -4px 0 0 -4px;
        }
      }
    }
  }
  &__indicator {
    background: @color-white;
    border-radius: @border-radius-full;
    &_absolute {
      left: 0;
      top: 9px;
    }
    &:before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: @border-radius-full;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -4px 0 0 -4px;
    }
  }
}