[class^="icon-"], [class*=" icon-"] {
  font-size: 24px;
  vertical-align: middle;
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 1;
  color: @color-gray;
  .transition-color();
}

.icon-arrow-dbl, .icon-arrow {
  &_left {
    transform: rotate(90deg);
  }
  &_right {
    transform: rotate(-90deg);
  }
  &_top {
    transform: rotate(180deg);
  }
  &_bottom {
    transform: rotate(0deg);
  }
}

.icon-big-done,
.icon-done-2,
.icon-password-gr,
.icon-invited-big-user,
.icon-big-group,
.icon-big-users,
.icon-trophy-big,
.icon-without-content,
.icon-folder_big,
.icon-folder-open,
.icon-big-fired,
.icon-trophy-big,
.icon-favorites,
.icon-gift,
.icon-big-trashcan,
.icon-trashcan-modal,
.icon-search-content,
.icon-add-member,
.icon-add-member-with-starts,
.icon-tests-illustration,
.icon-enter,
.icon-email-big,
.icon-password,
.icon-password-grey,
.icon-big-exit,
.icon-without-course,
.icon-without-test,
.icon-password-bl {
  background-position: @center @center !important;
  width: 168px;
  height: 168px;
  background-size: 168px 168px !important;
  display: inline-block;
}

.icon-big-exit {
  background: url(../interface/exit.svg) no-repeat;
}

.icon-add-member {
  background: url(../interface/add-member.svg) no-repeat;
}

.icon-add-member-with-starts {
  background: url(../interface/add-member-with-starts.svg) no-repeat;
}

.icon-invited-big-user {
  background: url(../interface/big-invited-users.svg) no-repeat;
}

.icon-big-arrow {
  background: url(../interface/arrow.svg) no-repeat;
}

.icon-big-users {
  background: url(../interface/big-users.svg) no-repeat;
}

.icon-search-content {
  background: url(../interface/search-content.svg) no-repeat;
}

.icon-trashcan-modal {
  background: url(../interface/trashcan-modal.svg) no-repeat;
}

.icon-big-trashcan {
  background: url(../interface/trashcan.svg) no-repeat;
}

.icon-gift {
  background: url(../interface/gift.svg) no-repeat;
}

.icon-favorites {
  background: url(../interface/favorites.svg) no-repeat;
}

.icon-big-fired {
  background: url(../interface/fired.svg) no-repeat;
}

.icon-folder {
  background: url(../interface/folder.svg) no-repeat;
}

.icon-folder-open {
  background: url(../interface/foulder-open.svg) no-repeat;
}

.icon-password {
  background: url(../interface/password.svg) no-repeat;
}

.icon-big-done {
  background: url(../interface/done.svg) no-repeat;
}

.icon-enter {
  background: url(../interface/enter.svg) no-repeat;
}

.icon-email-big {
  background: url(../interface/mail.svg) no-repeat;
}

.icon-password-bl {
  background: url(../interface/password-bl.svg) no-repeat;
}

.icon-password-gr {
  background: url(../interface/password-grey.svg) no-repeat;
}

.icon-tests-illustration {
  background: url(../interface/tests-illustration.svg) no-repeat;
}

.icon-without-content {
  background: url(../interface/not-content.svg) no-repeat;
}

.icon-trophy-big {
  background: url(../interface/trophy.svg) no-repeat;
}

.icon-big-group {
  background: url(../interface/group.svg) no-repeat;
}

.icon-done-2 {
  background: url(../interface/done2.svg) no-repeat;
}

.icon-without-course {
  background: url(../interface/no-courses.svg) no-repeat;
}

.icon-without-test {
  background: url(../interface/no-tests.svg) no-repeat;
}

.icon-arrow-right {
  transform: rotate(270deg);
}

.icon-xs {
  font-size: 44px;
  width: 44px;
  height: 44px;
}

.icon-close, .icon-search, .icon-filter, .icon-pencil, .icon-downarrow, .icon-comments, .icon-menu, .icon-cardview, .icon-listview {
  cursor: pointer;
  .transition-color();
  &:hover {
    color: @color-dark-gray;
  }
}

.icon-social {
  font-size: 21px;
  width: 22px;
  height: 22px;
  text-align: @center;
  display: flex;
  align-items: @center;
  justify-content: @center;
}

.icon-pin, .icon-green {
  color: @color-green;
}

.icon-star, .icon-gold {
  color: @color-gold;
}

// TODO: rename to color-light-blue
.icon-blue {
  color: @color-light-blue;
}

// TODO: rename to color-blue
.icon-real-blue {
  color: @color-blue;
}

.icon-red {
  color: @color-red;
}

.icon-orange {
  color: @color-orange;
}

.icon-yellow {
  color: @color-yellow;
}

.icon-white {
  color: @color-white;
}

.icon-dark-gray {
  color: @color-dark-gray;
}
