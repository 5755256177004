.input {
  .form-prop();
  display: block;
  padding: 11px 16px;
  height: 40px;

  &_padding-right {
    padding-right: 48px;
  }

  &_padding-right-90 {
    padding-right: 90px;
  }

  &_padding-right-27 {
    padding-right: 27px;
  }

  &_padding-left-0 {
    padding-left: 0;
  }

  &_padding-left {
    padding-left: 48px;
  }

  &_padding-side {
    padding: 11px 48px;
  }

  &_margin-top {
    margin-top: 7px;
  }

  &_margin-top-16-72 {
    margin: 16px 0 72px;
  }

  &_margin-10-20 {
    margin: 10px 0 20px;
  }

  &_transparent {
    border: @none;
  }

  &_sidebar {
    padding: 0 55px 0 0;

    & ~ .form__icon_right {
      &:last-child {
        margin-right: -16px;
      }
    }

    & + .form__icon_right {
      right: 10px;
    }
  }

  &:disabled {
    background-color: @color-gray-white;
    border-color: @color-light-gray;
  }

  &__btn-search {
    border: none;
    background-color: transparent;
    cursor: pointer;
    top: 0;
    bottom: 0;
    width: 50px;
    .transition-color();

    &:hover {
      .icon-search {
        color: @color-dark-gray;
      }
    }
  }

  &_search-without-border {
    border-radius: 0;
    border: 0;
    border-bottom: @border-default;
  }
}
