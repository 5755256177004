.ui-select {
  &__add {
    margin-top: -10px;
    padding-left: 14px;
    padding-bottom: 10px;
    font-size: 14px;

    display: flex;
    align-items: center;
    cursor: pointer;
    .user-select();
    color: @color-blue;

    @media (max-width: 550px) {
      margin-top: -14px;
      background-color: #fff;
      position: relative;
      z-index: 9999;
      padding-top: 15px;
      padding-bottom: 0!important;
    }
  }
  &__icon-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;

    &[hidden] {
      display: none;
    }
  }
  &__scroll-content {
    max-height: 225px;
    overflow: auto;
    margin: 14px 0;
    padding: 0 8px;
    @media(max-width: 550px) {
      background-color: #fff;
      z-index: 9999999;
      position: relative;
      padding-top: 14px;
      padding-bottom: 14px;
      border-radius: @border-radius-default @border-radius-default 0 0;
      margin: 0;
    }
  }

  &__prev-item {
    padding-left: 14px!important;
    margin-bottom: 0;
    display: none !important;
    @media (max-width: 550px) {
      display: flex !important;
    }
  }

  &__inner-wrap {
    @media (max-width: 550px) {
      background-color: #fff;
      position: relative;
      box-sizing: border-box;
      padding-top: 32px;
      padding-bottom: 24px;
      border-radius: @border-radius-default @border-radius-default 0 0;
    }
  }

  &__popup {
    margin-bottom: 20px;

    @media (max-width: 550px) {
      left: 0!important;
      right: 0!important;
      position: fixed;
      bottom: 0;
      top: initial!important;
      z-index: 999;
      margin-bottom: 0;
      background-color: rgba(255, 255, 255, 0);
    }
  }

  &__inner-input,
  &__new {
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: @color-light-gray;
    }
  }

  &__inner-input {
    margin-bottom: 7px;
    padding: 8px 10px 8px 20px;

    @media (max-width: 550px) {
      margin-bottom: -8px;
      background: #fff;
    }

    &:before {
      content: "";
      bottom: 0;
      @media (max-width: 550px) {
        display: none;
      }
    }
  }

  &__new {
    padding: 12px 10px 12px;
    line-height: 1;
    margin-top: -5px;
    cursor: pointer;

    @media (max-width: 550px) {
      background-color: #fff;
      margin-top: -15px;
    }

    &:before {
      content: "";
      top: 0;
    }
  }

  &__new-text {

    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: -0.1px;
    color: @color-gray;
    padding-left: 10px;
    line-height: 32px;
  }

  &__new-val {
    display: block;

    font-size: 14px;
    color: @color-green;
    padding: 9px 10px;
    border-radius: @border-radius-default;

    @media (max-width: 550px) {
      padding: 8px 22px;
      background: #f5f5f7;
      color: #556066;
    }

    &:empty {
      margin: 0;
    }

    &:hover {
      .select__new-val {
        background: @color-gray-blue;
      }
    }
  }

  &__input {
    border: none;
    padding: 0 40px 0 0;

    @media (max-width: 550px) {
      border-bottom: 1px solid #e4e5e9;
      border-radius: 0px;
    }

    @media (max-width: 550px) {
      padding-left: 5px;
    }
  }

  &__header {
    border-radius: @border-radius-default;
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 11px 40px 11px 16px;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    color: @color-dark-gray;
    .form-prop();

    &_active {
      border-color: @color-light-blue;

      &:hover {
        border-color: @color-light-blue;
      }
    }

    ::ng-deep i {
      margin-right: 8px;
    }
  }

  &__icon-arrow {
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 10px;
  }

  &__icon-search {
    position: absolute;
    top: 16px;
    right: 13px;
  }
}
