// legacy code
// deprecated
.tooltip-inner {
  cursor: pointer;
  &:hover {
    .tooltip-help {
      opacity: 1;
      visibility: visible;
    }
  }
}
.tooltip-help {
  text-transform: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -11px;
  bottom: 100%;
  width: 272px;
  margin-bottom: 15px;
  font-size: 14px;
  padding: 10px 14px;
  box-sizing: border-box;
  color: @color-white;
  background-color: @color-blue;
  border-radius: @border-radius-default;
  .transition-visability();
  &:before {
    content: '';
    height: 12px;
    background-color: @color-blue;
    width: 12px;
    left: 17px;
    bottom: -6px;
    position: absolute;
    transform: rotate(45deg);
  }
}
