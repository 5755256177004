/*imports*/
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "less/tree";
@import "less/badge";
@import "less/icons";
@import "less/icons-custom";
@import "less/fonts";
@import "less/variables";
@import "less/link";
@import "less/user-status";
@import "less/form/form";
@import "less/typography";
@import "less/form/tooltip.less";
@import "less/mixins";
/*imports components*/
@import "less/dialog";
@import "less/tooltip-help";
@import "less/content-item";
@import "less/main";
@import "less/matdialog";
@import "less/content-text";
@import "less/datepicker";
@import "less/picker";
@import "less/redactor";
/*third-party*/
@import '~@angular/cdk/overlay-prebuilt.css';

html, body {
  font-family: ProximaNova, Arial, sans-serif;
  font-weight: normal;
  min-height: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  // .body-1-text();
  display: flex;
  flex-direction: column;
  box-sizing: @border-box;
  position: relative;
  background-color: @color-gray-blue;
  min-width: 303px;
  color: @grey-1;
  --ck-z-modal: 9999999999;
}

// sprite
body > svg {
  display: none;
 }

input, textarea, select, button {
  font-family: ProximaNova, Arial, sans-serif;
}

*:focus {
  outline: none;
}

* {
  -webkit-overflow-scrolling: touch;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

#chatra:not(.chatra--expanded) {
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none;
  transition: none;
}

ui-popup:not(.create-buttons__popup) .popup__arrow {
  @media (max-width: 550px) {
    background-color: rgba(0, 0, 0, 0.6) !important;
    width: 100% !important;
    height: 100% !important;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    display: block !important;
    transform: initial !important;
  }
}

.icon-arrow-left {
  transform: rotate(90deg);
}

.not-mobile {
  @media @Media-max-550 {
    display: none;
  }
}
