@font-face {
	font-family: "icon";
	src: url('/assets/fonts/icon.eot');
	src: url('/assets/fonts/icon.eot?#iefix') format('eot'),
		url('/assets/fonts/icon.woff') format('woff'),
		url('/assets/fonts/icon.ttf') format('truetype'),
		url('/assets/fonts/icon.svg#icon') format('svg');
}

.icon-base-pseudo {
	font-family: "icon";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

.icon-char(@filename) {
	@alert: "\E001";
	@align-centered: "\E002";
	@align-left: "\E003";
	@align-right: "\E004";
	@arrow-dbl: "\E005";
	@arrow-left: "\E006";
	@arrow: "\E007";
	@arrowinput: "\E008";
	@arrows-three: "\E009";
	@badge: "\E00A";
	@base: "\E00B";
	@behance: "\E00C";
	@bold: "\E00D";
	@book: "\E00E";
	@brush: "\E00F";
	@burger-menu: "\E010";
	@bylink-indicator: "\E011";
	@camera: "\E012";
	@cardview: "\E013";
	@chart-small: "\E014";
	@chart: "\E015";
	@close: "\E016";
	@code: "\E017";
	@comments: "\E018";
	@company: "\E019";
	@crossed: "\E01A";
	@crown: "\E01B";
	@dblarrow: "\E01C";
	@diamond: "\E01D";
	@divider: "\E01E";
	@document: "\E01F";
	@documents: "\E020";
	@done: "\E021";
	@downarrow: "\E022";
	@dropbox: "\E023";
	@edit: "\E024";
	@email: "\E025";
	@emoji: "\E026";
	@erasor: "\E027";
	@eye: "\E028";
	@facebook: "\E029";
	@failed: "\E02A";
	@filter: "\E02B";
	@fire-empty: "\E02C";
	@fire-full: "\E02D";
	@fire: "\E02E";
	@fired: "\E02F";
	@fullscreen: "\E030";
	@ghost: "\E031";
	@google: "\E032";
	@googledrive: "\E033";
	@h1: "\E034";
	@h2: "\E035";
	@hat: "\E036";
	@hats: "\E037";
	@heart-full: "\E038";
	@heart: "\E039";
	@help: "\E03A";
	@home: "\E03B";
	@info: "\E03C";
	@instagram: "\E03D";
	@invited-users: "\E03E";
	@italic: "\E03F";
	@keyboard: "\E040";
	@lamp: "\E041";
	@leaf: "\E042";
	@link: "\E043";
	@list: "\E044";
	@listview: "\E045";
	@location: "\E046";
	@lock: "\E047";
	@mail: "\E048";
	@menu: "\E049";
	@minus: "\E04A";
	@moderator: "\E04B";
	@movetool: "\E04C";
	@notification: "\E04D";
	@ok: "\E04E";
	@open: "\E04F";
	@ordered-list: "\E050";
	@pallette: "\E051";
	@pencil: "\E052";
	@period: "\E053";
	@phone: "\E054";
	@picture: "\E055";
	@pin: "\E056";
	@plus-add: "\E057";
	@preview: "\E058";
	@progress: "\E059";
	@quote: "\E05A";
	@replace: "\E05B";
	@restore: "\E05C";
	@rocket: "\E05D";
	@sad-face: "\E05E";
	@sand-clock: "\E05F";
	@scripts: "\E060";
	@search: "\E061";
	@skype: "\E062";
	@sound: "\E063";
	@star-full: "\E064";
	@star-rating: "\E065";
	@star: "\E066";
	@status: "\E067";
	@streched: "\E068";
	@styles: "\E069";
	@sunglasses: "\E06A";
	@t-centered: "\E06B";
	@t-left: "\E06C";
	@t-right: "\E06D";
	@table-column: "\E06E";
	@table-row: "\E06F";
	@table-style: "\E070";
	@table: "\E071";
	@tag: "\E072";
	@task: "\E073";
	@team: "\E074";
	@telegram: "\E075";
	@trash: "\E076";
	@trashcan: "\E077";
	@trophy: "\E078";
	@twitter: "\E079";
	@underline: "\E07A";
	@unlink: "\E07B";
	@up-arrow: "\E07C";
	@uparrow: "\E07D";
	@upload: "\E07E";
	@upndown: "\E07F";
	@user-admin: "\E080";
	@user-indicator: "\E081";
	@user-link: "\E082";
	@user: "\E083";
	@viber: "\E084";
	@video: "\E085";
	@vk: "\E086";
	@wallet: "\E087";
	@warning: "\E088";
	@whatsapp: "\E089";
	@youtube: "\E08A";
	
	content: @@filename;
}

.icon(@filename, @insert: before) {
	@pseudo-selector: ~":@{insert}";

	&@{pseudo-selector} {
		&:extend(.icon-base-pseudo);
		.icon-char(@filename);
	}
}

.icon-alert {
	.icon(alert);
}
.icon-align-centered {
	.icon(align-centered);
}
.icon-align-left {
	.icon(align-left);
}
.icon-align-right {
	.icon(align-right);
}
.icon-arrow-dbl {
	.icon(arrow-dbl);
}
.icon-arrow-left {
	.icon(arrow-left);
}
.icon-arrow {
	.icon(arrow);
}
.icon-arrowinput {
	.icon(arrowinput);
}
.icon-arrows-three {
	.icon(arrows-three);
}
.icon-badge {
	.icon(badge);
}
.icon-base {
	.icon(base);
}
.icon-behance {
	.icon(behance);
}
.icon-bold {
	.icon(bold);
}
.icon-book {
	.icon(book);
}
.icon-brush {
	.icon(brush);
}
.icon-burger-menu {
	.icon(burger-menu);
}
.icon-bylink-indicator {
	.icon(bylink-indicator);
}
.icon-camera {
	.icon(camera);
}
.icon-cardview {
	.icon(cardview);
}
.icon-chart-small {
	.icon(chart-small);
}
.icon-chart {
	.icon(chart);
}
.icon-close {
	.icon(close);
}
.icon-code {
	.icon(code);
}
.icon-comments {
	.icon(comments);
}
.icon-company {
	.icon(company);
}
.icon-crossed {
	.icon(crossed);
}
.icon-crown {
	.icon(crown);
}
.icon-dblarrow {
	.icon(dblarrow);
}
.icon-diamond {
	.icon(diamond);
}
.icon-divider {
	.icon(divider);
}
.icon-document {
	.icon(document);
}
.icon-documents {
	.icon(documents);
}
.icon-done {
	.icon(done);
}
.icon-downarrow {
	.icon(downarrow);
}
.icon-dropbox {
	.icon(dropbox);
}
.icon-edit {
	.icon(edit);
}
.icon-email {
	.icon(email);
}
.icon-emoji {
	.icon(emoji);
}
.icon-erasor {
	.icon(erasor);
}
.icon-eye {
	.icon(eye);
}
.icon-facebook {
	.icon(facebook);
}
.icon-failed {
	.icon(failed);
}
.icon-filter {
	.icon(filter);
}
.icon-fire-empty {
	.icon(fire-empty);
}
.icon-fire-full {
	.icon(fire-full);
}
.icon-fire {
	.icon(fire);
}
.icon-fired {
	.icon(fired);
}
.icon-fullscreen {
	.icon(fullscreen);
}
.icon-ghost {
	.icon(ghost);
}
.icon-google {
	.icon(google);
}
.icon-googledrive {
	.icon(googledrive);
}
.icon-h1 {
	.icon(h1);
}
.icon-h2 {
	.icon(h2);
}
.icon-hat {
	.icon(hat);
}
.icon-hats {
	.icon(hats);
}
.icon-heart-full {
	.icon(heart-full);
}
.icon-heart {
	.icon(heart);
}
.icon-help {
	.icon(help);
}
.icon-home {
	.icon(home);
}
.icon-info {
	.icon(info);
}
.icon-instagram {
	.icon(instagram);
}
.icon-invited-users {
	.icon(invited-users);
}
.icon-italic {
	.icon(italic);
}
.icon-keyboard {
	.icon(keyboard);
}
.icon-lamp {
	.icon(lamp);
}
.icon-leaf {
	.icon(leaf);
}
.icon-link {
	.icon(link);
}
.icon-list {
	.icon(list);
}
.icon-listview {
	.icon(listview);
}
.icon-location {
	.icon(location);
}
.icon-lock {
	.icon(lock);
}
.icon-mail {
	.icon(mail);
}
.icon-menu {
	.icon(menu);
}
.icon-minus {
	.icon(minus);
}
.icon-moderator {
	.icon(moderator);
}
.icon-movetool {
	.icon(movetool);
}
.icon-notification {
	.icon(notification);
}
.icon-ok {
	.icon(ok);
}
.icon-open {
	.icon(open);
}
.icon-ordered-list {
	.icon(ordered-list);
}
.icon-pallette {
	.icon(pallette);
}
.icon-pencil {
	.icon(pencil);
}
.icon-period {
	.icon(period);
}
.icon-phone {
	.icon(phone);
}
.icon-picture {
	.icon(picture);
}
.icon-pin {
	.icon(pin);
}
.icon-plus-add {
	.icon(plus-add);
}
.icon-preview {
	.icon(preview);
}
.icon-progress {
	.icon(progress);
}
.icon-quote {
	.icon(quote);
}
.icon-replace {
	.icon(replace);
}
.icon-restore {
	.icon(restore);
}
.icon-rocket {
	.icon(rocket);
}
.icon-sad-face {
	.icon(sad-face);
}
.icon-sand-clock {
	.icon(sand-clock);
}
.icon-scripts {
	.icon(scripts);
}
.icon-search {
	.icon(search);
}
.icon-skype {
	.icon(skype);
}
.icon-sound {
	.icon(sound);
}
.icon-star-full {
	.icon(star-full);
}
.icon-star-rating {
	.icon(star-rating);
}
.icon-star {
	.icon(star);
}
.icon-status {
	.icon(status);
}
.icon-streched {
	.icon(streched);
}
.icon-styles {
	.icon(styles);
}
.icon-sunglasses {
	.icon(sunglasses);
}
.icon-t-centered {
	.icon(t-centered);
}
.icon-t-left {
	.icon(t-left);
}
.icon-t-right {
	.icon(t-right);
}
.icon-table-column {
	.icon(table-column);
}
.icon-table-row {
	.icon(table-row);
}
.icon-table-style {
	.icon(table-style);
}
.icon-table {
	.icon(table);
}
.icon-tag {
	.icon(tag);
}
.icon-task {
	.icon(task);
}
.icon-team {
	.icon(team);
}
.icon-telegram {
	.icon(telegram);
}
.icon-trash {
	.icon(trash);
}
.icon-trashcan {
	.icon(trashcan);
}
.icon-trophy {
	.icon(trophy);
}
.icon-twitter {
	.icon(twitter);
}
.icon-underline {
	.icon(underline);
}
.icon-unlink {
	.icon(unlink);
}
.icon-up-arrow {
	.icon(up-arrow);
}
.icon-uparrow {
	.icon(uparrow);
}
.icon-upload {
	.icon(upload);
}
.icon-upndown {
	.icon(upndown);
}
.icon-user-admin {
	.icon(user-admin);
}
.icon-user-indicator {
	.icon(user-indicator);
}
.icon-user-link {
	.icon(user-link);
}
.icon-user {
	.icon(user);
}
.icon-viber {
	.icon(viber);
}
.icon-video {
	.icon(video);
}
.icon-vk {
	.icon(vk);
}
.icon-wallet {
	.icon(wallet);
}
.icon-warning {
	.icon(warning);
}
.icon-whatsapp {
	.icon(whatsapp);
}
.icon-youtube {
	.icon(youtube);
}