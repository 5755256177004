.check-box {
  .radio-check-box-prop();
  width: 100%;
  line-height: 36px;

  &_line-height-28 {
    line-height: 28px;
  }

  &_inline {
    display: inline-block;
    width: auto;
    margin-right: 12px;
  }

  &__text {
    &_bold {
      font-weight: 600;
    }
    &_16px {
      font-size: 16px;
    }
    &_black {
      color: @color-light-black;
    }
  }

  &__input {
    &:checked, &:disabled {
      & + .check-box__indicator {
        &:before {
          content: "";
          box-sizing: content-box;
          width: 7px;
          height: 3px;
          border: 1px solid transparent;
          position: absolute;
          transform: rotate(-45deg);
          top: 50%;
          left: 50%;
          margin: -4px 0 0 -4px;
        }
      }
    }

    &:checked {
      & + .check-box__indicator {
        &_blue:before {
          content: "";
          border-left-color: @color-blue;
          border-bottom-color: @color-blue;
        }
      }
    }

    &:disabled &:checked {
      & ~ .check-box__indicator {
        &:before {
          content: "";
          border-left-color: @color-default-gray;
          border-bottom-color: @color-default-gray;
        }
      }
    }
  }

  &__indicator {
    position: relative;
    z-index: 1;
    border-radius: @border-radius-form;

    &_absolute {
      left: 0;
      top: 0;
    }
  }
}

.check-box-2 {
  display: block;
  position: relative;
  padding-left: 32px;
  margin: 20px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.3;

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #d0d0d0;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:hover {
      border-color: #b5b5b5;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .check-box-2__checkmark {
      background-color: #2196F3;
      border-color: #2196F3;

      &:after {
        display: block;
      }
    }
  }
}

