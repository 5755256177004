@import 'variables';

.container-prop() {
  background-color: @color-white;
  box-shadow: -1px 1px 4px 0 rgba(58, 70, 93, 0.12);
  border-radius: @border-radius-default;
}

.transition-color() {
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}

.transition-border-color() {
  -webkit-transition: border-color .2s ease;
  transition: border-color .2s ease;
}

.transition-bg-color() {
  -webkit-transition: background-color .2s ease;
  transition: background-color .2s ease;
}

.transition-opacity() {
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
}

.transition-all() {
  transition: all .2s ease;
  -vendors-transition: all .2s ease;
}

.transition-shadow() {
  -webkit-transition: box-shadow .2s ease;
  transition: box-shadow .2s ease;
}

.disabled() {
  pointer-events: @none;
}

.transition-visability() {
  transition: all .3s ease .15s;
}

.transition() {
  -webkit-transition-delay: .1s;
  -o-transition-delay: .1s;
  -moz-transition-delay: .1s;
}

.transition-open() {
  -webkit-transition: .3s ease-out;
  -webkit-transition-delay: .1s;
  -o-transition: .3s ease-out;
  -o-transition-delay: .1s;
  -moz-transition: .3s ease-out;
  -moz-transition-delay: .1s;
  transition: .3s ease-out;
  transition-delay: .1s;
  opacity: 1;
}

.margin-padding-zero() {
  margin: 0;
  padding: 0;
}

.popup-prop () {
  border-radius: @border-radius-default;
  background-color: @color-white;
  box-shadow: -7px 8px 16px 0 rgba(58, 70, 93, 0.07), -1px 1px 4px 0 rgba(58, 70, 93, 0.12);
}

.user-select () {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.form-prop () {
  font-size: 14px;
  //font-family: @proxy-reg;
  color: @color-dark-gray;
  border-radius: @border-radius-form;
  border: @border-default;
  box-sizing: @border-box;
  width: 100%;
  -webkit-appearance: none;
  &::-webkit-search-cancel-button {
    display: none;
  }
  &::-webkit-input-placeholder {
    color: @color-gray;
  }
  .transition-border-color();
  &:hover {
    border-color: @color-default-gray;
  }
  &:focus {
    border-color: @color-light-blue;
  }
}

.radio-check-box-prop () {
  &__sub-text, &__text {
    margin-left: 8px;
  }
  &__sub-text {
    font-size: 12px;
    line-height: 15px;
    color: @color-default-gray;
    margin-top: 0;
  }
  &__text {
    line-height: 1;
    font-size: 14px;
    color: @color-gray;
    display: inline-block;
    vertical-align: middle;
    .transition-color();
  }
  &__input {
    display: @none;

    &:checked {
      & ~ .check-box__text, & ~ .radio__text {
        color: @color-dark-gray;
      }
    }

    &:disabled {
      & ~ .check-box__text, & ~ .radio__text {
        color: @color-gray !important;
        cursor: default;
      }

      & ~ .check-box__indicator, & ~ .radio__indicator {
        background-color: @color-super-light-gray;
        cursor: default;
      }
    }
  }
  &__label {
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    &:hover {
      .check-box__indicator, .radio__indicator {
        background-color: @color-super-light-gray;
      }

      .check-box__text, .radio__text {
        color: @color-dark-gray;
      }
    }

    &_disabled {
      cursor: default;
    }

    &_padding-left {
      padding: 8px 0 8px 20px;
      position: relative;
      z-index: 1;
    }

    &_block {
      display: block;
      padding-top: 10px;

      span, p {
        display: block;
        margin-bottom: 5px;
        vertical-align: top;
      }
    }

  }
  &__indicator {
    border: @border-default;
    box-sizing: @border-box;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
    .transition-bg-color();

    &_absolute {
      position: absolute;
      left: 0px;
      top: 9px;
      z-index: 2;
    }
  }
}

.hover-item() {
  background-color: @color-gray-blue;
  border-radius: @border-radius-default;
  color: @color-dark-gray;
  cursor: pointer;
  .transition-all();
}

.menu-dots() {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 48px;
  height: 40px;
  .icon-menu {
    .transition-color();
  }
  &:hover {
    .icon-menu {
      color: @color-dark-gray;
    }
  }
}

.label-popup-prop() {
  font-size: 16px;
  font-weight: 600;
  color: @color-light-black;
  display: block;
}

.icon-prop() {
  font-family: "icon";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}


// Контейнер форм диалога
.form-dialog-container {
  position: relative;
  margin: 0 auto;
  width: 90%;
  max-width: 800px;
}
